import React from "react";
import styles from "./style.module.css";
import { Document, Page, Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import { useContext } from "react";
import { AuthContext } from "../../authContext";
import { useEffect } from "react";
import { useState } from "react";

const PDFrender = (props) =>
{
	const context = useContext(AuthContext);
	const [cashs, setCashs] = useState({cashIn: 0, cash: 0, cashOut: 0});

	const dateMaker = (date) =>
	{
		const newDate = new Date(date.toString());
		return newDate.getFullYear() + "/" + Number.parseInt(newDate.getMonth()+1) + "/" + newDate.getDate()
	}

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'column',
			height: 300,
			padding: 20,
		},
		section: {
			margin: 10,
			padding: 10,
		},
		reportTxt: {
			fontSize: 18,
			marginBottom: 10,
			textAlign: "center",
		},
		header: {
			flexDirection: "row",
			backgroundColor: "rgb(255 0 0)",
			color: "white",
			alignItems: "center",
			justifyContent: "space-between",
			paddingHorizontal: 10,
			paddingVertical: 5,
		},
		transaction: {
			backgroundColor: "rgba(135,135,135, 0.1)",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			paddingVertical: 10,	
			paddingHorizontal: 10,	
			marginTop: 2,
			borderRadius: 10,
		},
		title: {
			fontSize: 16,
			marginBottom: 10,
		},
		text: {
			fontSize: 14,
		},
		link: {
			fontSize: 14,
			marginTop: 18,
		},
		cashs: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-around",
			marginTop: 20,
		}
	});

	useEffect(() =>
	{
		let data = {cashIn: 0, cashOut: 0};

		props.data.forEach(element => {
			if (element.type)
				return data = {cashIn: data.cashIn + element.amount, cash: data.cash, cashOut: data.cashOut};
			data = {cashIn: data.cashIn, cash: data.cash, cashOut: data.cashOut + element.amount};
		});

		setCashs({cashIn: data.cashIn, cash: data.cashIn - data.cashOut, cashOut: data.cashOut});
	}, [props.data]);

	return (
		<Document>
			<Page style={styles.page}>
				<Text style={styles.reportTxt}>{props.owner}</Text>
				{/* <Text style={styles.title}>Owner: {props.owner} To: {props.totalCashInOut.customerName}</Text> */}
				<Text style={styles.text}>Date: {new Date(new Date()).toLocaleString()}</Text>

				<View style={styles.header}>
						<Text style={styles.title}>No</Text>
						<Text style={styles.title}>Cash Out</Text>
						<Text style={styles.title}>Cash In</Text>
						<Text style={styles.title}>Currency</Text>
						<Text style={styles.title}>Information</Text>
						<Text style={styles.title}>Date</Text>
				</View>

				{props.data.map((value, index) => (
					<View key={value.id} style={styles.transaction}>
						<Text style={styles.text}>{index + 1}</Text>
						<Text style={styles.text}>{!value.type && value.amount}</Text>
						<Text style={styles.text}>{value.type && value.amount}</Text>
						<Text style={styles.text}>{props.currencyCode === "؋" ? "AFG" : props.currencyCode}</Text>
						<Text style={styles.text}>{value.information}</Text>
						<Text style={styles.text}>{dateMaker(value.dateTime)}</Text>
					</View>
				))}

				<View style={styles.cashs}>
					<Text style={styles.text}>Cash In: {cashs.cashIn}</Text>
					<Text style={styles.text}>Cash: {cashs.cash}</Text>
					<Text style={styles.text}>Cash Out: {cashs.cashOut}</Text>
				</View>
				<View style={styles.cashs}>
					<Link style={styles.link} src="https://play.google.com/store/apps/details?id=com.mosaaghajahanmal.brainbbox">Play Store link</Link>
					<Link style={styles.link} src="https://play.google.com/store/apps/details?id=com.mosaaghajahanmal.brainbbox">App Store link</Link>
					<Link style={styles.link} src="https://brainbbox.com">Web link</Link>
				</View>
			</Page>
		</Document>
	)
};

export default PDFrender;