import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import languages from "../../localization";
import CashComponent from "../Cash";
import TransactionModal from "../TransactionModal";
import { FaRegTrashAlt } from "react-icons/fa";
import CashIn_Out from "../CashIn_Out";
import SortData from "../../utils/SortData";
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";
import serverPath, { khataServer } from "../../utils/serverPath";
import AskAlert from "../AskAlert";
import ReactPaginate from 'react-paginate';
import SweetAlert from "../SweetAlert";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import PDFrender from "../PDFrender";
import uniqueId from "../../utils/uniqueId";
import { useMediaQuery } from "react-responsive";

const Transactions = (props) =>
{
	const initState = {
		transactions: [],
		transModal: {visible: false, data: ""},
		cashInOutModal: {visible: false, type: 0},
		totalCashInOut: { cash: 0, cashIn: 0, cashOut: 0, customerName: ""},
		from: "",
		to: "",
		share: false,

		currentItems: [],
		pageCount: 0,
		itemOffset: 0,
		itemsPerPage: 15,
	};
	
	const [ fields, setFields ] = useState(initState);
	const context = useContext(AuthContext);
	const [globalState, dispatch] = useStore();
	const cashbookId = props?.cashbookId;


	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	const pageCount = Math.ceil(fields?.transactions?.length / fields.itemsPerPage);
	const handlePageClick = (event) => {
		const newOffset = (event.selected * fields.itemsPerPage) % fields.transactions.length;
		onChange(newOffset, "itemOffset");
	};
	
	useEffect(() =>
	{
		const endOffset = fields.itemOffset + fields.itemsPerPage;
		onChange(fields.transactions.slice(fields.itemOffset, endOffset), "currentItems");
		onChange(Math.ceil(fields.transactions.length / fields.itemsPerPage), "pageCount");
	}, [fields.itemOffset, fields.itemsPerPage, fields.transactions]);

	useEffect(() =>
	{
		(async() =>
		{
			if (props.cashbookId && props.fromOpposite)
			{
				const cashbookUser = globalState.oppositeCustomers?.find(customer => customer.id || customer.summary[0].cashbookId === cashbookId);

				try {
					if (!cashbookId || !cashbookUser)
						return props.goBack();
					
					if (globalState.oppositeTransactions.length >= 1)
					{
						let cashTransactions = [];

						globalState.oppositeTransactions.find(trans => {
							if (trans.cashbookId === cashbookId && trans.currencyId === context.currency?.id)
								cashTransactions.push(trans);
						});

						if (cashTransactions.length >= 1)
							onChange(SortData(cashTransactions), "transactions");

						if (cashTransactions.length >= 1)
							return;
					};
				} catch (error) {
					console.log(error);
				}

				return;
			}

			if (props.cashbookId)
			{
				const cashbookUser = globalState.customers?.find(customer => customer.id || customer.summary[0].cashbookId === cashbookId);

				try {
					if (!cashbookId || !cashbookUser)
						return props.goBack();
					
					if (globalState.transactions.length >= 1)
					{
						let data = customerDataFinder(SortData(globalState.transactions));
						if (data.length >= 1)
							return;
					};
				} catch (error) {
					console.log(error);
				}
			}
		})();
	}, [globalState.transactions, globalState.oppositeTransactions]);

	useEffect(() => {
		(async () =>
		{
			if (props.fromOpposite)
			{
				const cashbookUser = globalState.oppositeCustomers?.find(customer => (customer.id || customer.summary[0].cashbookId) === cashbookId);
				let summary = cashbookUser?.summary.find(perCurrency => perCurrency.currencyId == context.currency?.id)
				onChange({
					cashIn: summary?.cashIn || 0,
					cash: summary?.cashIn - summary?.cashOut || 0,
					cashOut: summary?.cashOut || 0,
					customerName: [cashbookUser.owner.firstName, cashbookUser.owner.lastName].join(" "),
				}, "totalCashInOut")
				return;
			}

			const cashbookUser = globalState.customers?.find(customer => (customer.id || customer.summary[0].cashbookId) === cashbookId);
				let summary = cashbookUser?.summary.find(perCurrency => perCurrency.currencyId == context.currency?.id)
				onChange({
					cashIn: summary?.cashIn || 0,
					cash: summary?.cashIn - summary?.cashOut || 0,
					cashOut: summary?.cashOut || 0,
					customerName: [cashbookUser.customer.firstName, cashbookUser.customer.lastName].join(" "),
				}, "totalCashInOut")
		})();
	}, [cashbookId, globalState.transactions]);
	
	useEffect(() =>
	{
		(async () =>
		{
			if (fields.from && fields.to)
			{
				const fromDate = new Date(fields.from).getTime();
				const toDate = new Date(fields.to).getTime();

				const data = [];
				const findedTransactions = customerDataFinder(globalState.transactions);
				findedTransactions.forEach(per =>
					{
						const createdAtDate = new Date(per.createdAt).getTime();
						if (createdAtDate >= fromDate && createdAtDate <= toDate)
							data.push(per);
					})

				onChange(data, "transactions");
			};
		})();
	}, [fields.from, fields.to]);

	const customerDataFinder = (data) =>
	{
		let cashTransactions = [];
		data.find(trans => {
			if (trans.cashbookId === cashbookId && trans.currencyId === context.currency?.id)
				cashTransactions.push(trans);
		});
		// if (cashTransactions.length >= 1)
		onChange(cashTransactions, "transactions");

		return cashTransactions;
	};

	const dateChanger = (value, type) =>
	{
		if (type === "from")
		{
			if (fields.to)
			{
				if (!(fields.to >= value))
					return setFields(prev => ({ ...prev, from: "", to: "" }));
			}

			setFields(prev => ({ ...prev, from: value, to: fields.to }));
		} else {
			
			if (!(fields.from >= value))
				return setFields(prev => ({ ...prev, from: fields.from, to: value }));
		
			setFields(prev => ({ ...prev, from: fields.from, to: "" }));
		}
	}

	const deleteHandler = async (item) =>
	{
		const sure = await AskAlert({text: "Are you sure to delete this!"})
		if (!sure)
			return;

		const response = await fetch(khataServer("/transaction"), {
			method: "DELETE",
			headers: {
				"Content-Type": "Application/JSON",
			},
			body: JSON.stringify({ id: item.id, providerId: context.user.id })
		});
		
		const objData = await response.json();
		if (objData.status === "failure")
			return;

		if (objData.status === "success")
		{
			if (item.isReceivedMobile)
			{
				// const syncResponse = await fetch(serverPath("/app/api/sync"), {
				const syncResponse = await fetch(khataServer("/sync"), {
					method: "POST",
					headers: {
						"Content-Type": "Application/JSON",
					},
					body: JSON.stringify({ providerId: context.user.id, type: "delete", tableName: "transactions", data: JSON.stringify({id: item.id}), customerId: context?.customer?.id })
				});
				const syncObjData = await syncResponse.json();
			}
			
			dataManager(item);
		}
	};

	const dataManager = async (item) =>
	{
		let cloneCustomers = [...globalState.customers];
		let cashBookIndex = cloneCustomers.findIndex(per => per.id == cashbookId);
		if(cashBookIndex < 0)
			return SweetAlert("info", "PLease Try Again!")
		let cloneSummary = [...cloneCustomers[cashBookIndex]?.summary];
		let summaryIndex = cloneSummary.findIndex(per => per.currencyId == item.currencyId)
		if(summaryIndex < 0)
			return SweetAlert("info", "PLease Try Again!")
		let In_Out_Amount = cloneSummary[summaryIndex][item.type ? "cashIn" : "cashOut"];
		if (item.type === false || item.type === 0)
		{
			let newInAmount = (cloneSummary[summaryIndex][item.type ? "cashIn" : "cashOut"] + item.amount)
			cloneSummary[summaryIndex][item.type ? "cashIn" : "cashOut"] = newInAmount;
		}
		let newAmount = (In_Out_Amount - item.amount)
		let totalProfit = (cloneSummary[summaryIndex].totalProfit - item.profit)
		cloneSummary[summaryIndex][item.type ? "cashIn" : "cashOut"] = newAmount;
		cloneSummary[summaryIndex].totalProfit = totalProfit;
		cloneCustomers[cashBookIndex].summary = cloneSummary;
		const transactionsClone = [...globalState.transactions];
		let ndx = transactionsClone.findIndex(per => per.id == item.id)
		if (ndx >= 0)
			transactionsClone.splice(ndx, 1);
		dispatch("setData", {type: "customers", data: cloneCustomers});
		dispatch("setData", {type: "transactions", data: transactionsClone});
	};

	const pdfDownloadHandler = () =>
	{
		setTimeout(() =>
		{
			onChange(!fields.share, "share");
		}, 2500);
	};

	return (
		<div className="col-lg-12 col-12" style={{backgroundColor: "white"}}>
			{
				props.fromDate_ToDate &&
				<div className="input-group align-items-center mt-3">
					<div className="mx-2">
						<label htmlFor="from">{languages.fromDate}</label>
						<input type="date" name="from" id="from" className="form-control shadow-none" placeholder={languages.search} required value={fields.from} onChange={({target}) => dateChanger(target.value, "from")} />
					</div>
					<div className="mx-2">
						<label htmlFor="to">{languages.toDate}</label>
						<input type="date" name="to" id="to" className="form-control shadow-none" placeholder={languages.search} required value={fields.to} onChange={({target}) => dateChanger(target.value, "to")} />
					</div>
				</div>
			}

			{
				fields.share && <div style={{margin: 10}}> <PDFDownloadLink onClick={pdfDownloadHandler} document={ <PDFrender
					data={fields.transactions}
					owner={[context.customer?.firstName, context.customer?.lastName].join(" ")}
					totalCashInOut={fields.totalCashInOut}
					currencyCode={context?.currency?.code}
				/>} fileName={uniqueId()}>
				{({ blob, url, loading, error }) =>
				  loading ? 'Loading document...' : 'You Can Download your Share PDF here.'
				}
			  </PDFDownloadLink> </div>
			}

			<CashComponent
				goBack={props.goBack}
				goBackHandler={props.goBackHandler}
				share={props.share}
				shareHandler={() => onChange(!fields.share, "share")}
				cashs={fields.totalCashInOut}
			/>

			{!props.dontshowCashBTN && <div className="text-center my-2">
				<button className={["col-lg-3 col-12 bg-success text-white border-0 m-1 py-3 rounded-3", styles.cashBtn].join(" ")} onClick={() => onChange({visible: true, type: 1}, "cashInOutModal")}>Cash In</button>
				<button className={["col-lg-3 col-12 bg-danger text-white border-0 m-1 py-3 rounded-3", styles.cashBtn].join(" ")} onClick={() => onChange({visible: true, type: 0}, "cashInOutModal")}>Cash Out</button>
			</div>}
			
			<div className="row row-cols-3 align-items-center justify-content-center mt-2 shadow-sm text-center mx-0 py-2">
				<p className="m-0 fw-bold">{languages.dateTime}</p>
				<p className="m-0 fw-bold">{languages.cashOut}</p>
				<p className="m-0 fw-bold">{languages.cashIn}</p>
			</div>

			<div>
				{
					fields.transactions.length >= 1 ?
					<>
						{
							fields?.currentItems.map((value, index) => (
								<div style={{position: "relative"}} key={value.id}>
									<div
										className={[styles.cursor_pointer, "row row-cols-3 align-items-center justify-content-center mt-2 shadow-sm text-center mx-0 py-2"].join(" ")}
										onClick={() => onChange({visible: true, data: {...value, customerName: fields.totalCashInOut.customerName}}, "transModal")}
									>
										<p className="m-0">{new Date(value.dateTime).toLocaleString()}</p>
										<p className="m-0 text-danger">{!value.type ? value.amount : ""}</p>
										<p className="m-0 text-success">{value.type ? value.amount : ""}</p>
									</div>
									{ props.trash && <div className={styles.trashContainer} onClick={() => deleteHandler(value)}>
										<FaRegTrashAlt color="rgb(220,53,69)"  />
									</div> }
								</div>
							))
						}
						<ReactPaginate
							breakLabel={props?.isMobileSize ? "." : "..."}
							nextLabel={props?.isMobileSize ? ">" : "next >"}
							onPageChange={handlePageClick}
							pageRangeDisplayed={props?.isMobileSize ? 3 : 1}
							pageCount={pageCount}
							previousLabel={props?.isMobileSize ? "<" : "< previous"}
							renderOnZeroPageCount={null}
							containerClassName={styles.paginationContainer}
							pageLinkClassName={styles.pageNum}
							previousLinkClassName={styles.pageNum}
							nextLinkClassName={styles.pageNum}
							activeLinkClassName={styles.activePagi}
						/>
					</>
					:
						<div className="text-center mt-5">
							<p>No Transaction Avaiable</p>
						</div>
				}

			</div>

			<CashIn_Out
				visible={fields.cashInOutModal.visible}
				type={fields.cashInOutModal.type}
				cashbookId={cashbookId}
				cancel={() => onChange({visible: false, type: 0}, "cashInOutModal")}
			/>

			<TransactionModal
				visible={fields.transModal.visible}
				data={fields.transModal.data}
				cancel={() => onChange({visible: false, data: ""}, "transModal")}
			/>

			{/* {
				fields.share && <PDFViewer style={{ width: "100%", height: 800 }}>
                <PDFrender
					data={fields.transactions}
					owner={context.customer?.firstName}
					totalCashInOut={fields.totalCashInOut}
					currencyCode={context?.currency?.code}
				/>
				</PDFViewer>
			} */}
			
		</div>
	)
};

export default Transactions;